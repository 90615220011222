$(document).on 'change', '[data-role~=all_entities_checkbox]', ->
  maybeMarkAllCheckboxesAsChecked('single_entity_checkbox', this)

$(document).on 'change', '[data-role~=all_cards_checkbox]', ->
  maybeMarkAllCheckboxesAsChecked('mass_select_checkbox', this)

$(document).on 'change', '[data-role~=single_entity_checkbox]', ->
  maybeMarkCheckboxesAsChecked('single_entity_checkbox', 'all_entities_checkbox', this)

$(document).on 'change', '[data-role~=mass_select_checkbox]', ->
  maybeMarkCheckboxesAsChecked('mass_select_checkbox', 'all_cards_checkbox', this)

maybeMarkAllCheckboxesAsChecked = (single_selector, elem) ->
  if elem.checked
    $("[data-role~=#{single_selector}]").not('[disabled]').each ->
      @checked = true
      return
  else
    $("[data-role~=#{single_selector}]").not('[disabled]').each ->
      @checked = false
      return

maybeMarkCheckboxesAsChecked = (single_selector, all_selector, elem) ->
  checkedCheckboxes = $("[data-role~=#{single_selector}]:checked")
  allCheckboxes = $("[data-role~=#{single_selector}]")
  allCheckbox = $("[data-role~=#{all_selector}]")

  if false == $(elem).prop('checked')
    if $("[data-role~=#{single_selector}]:checked").length > 0
      allCheckbox.prop 'indeterminate', true
    else
      allCheckbox.prop 'indeterminate', false
      allCheckbox.prop 'checked', false
  if true == $(elem).prop('checked')
    if checkedCheckboxes.length > 0 && checkedCheckboxes.length != allCheckboxes.length
      allCheckbox.prop 'indeterminate', true
    else
      allCheckbox.prop 'indeterminate', false
      allCheckbox.prop 'checked', true
  if checkedCheckboxes.length == allCheckboxes.length
    allCheckbox.prop 'indeterminate', false
    allCheckbox.prop 'checked', true
  return
